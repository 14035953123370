<template>



    <!-- Main Navigation -->
    <nav class="navbar navbar-expand-md main-nav navigation fixed-top sidebar-left">
    <div class="container">
    <button class="navbar-toggler" type="button">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>

    <a href="#main" class="navbar-brand">
        
            <img src="../assets/img/logonobg.png" alt="Laapp" class="logo logo-sticky d-block d-md-none">
            <img src="../assets/img/logonobg.png" alt="Laapp" class="logo d-none d-md-block">
        
    </a>

    <div class="collapse navbar-collapse" id="main-navbar">
        <div class="sidebar-brand">
            <a href="index.html">
                <img src="../assets/img/logonobg.png" alt="Laapp Template" class="logo">
            </a>
        </div>

        <ul class="nav navbar-nav ml-auto">
            <li class="nav-item" @click="scrollToSection('features')" style="cursor: pointer;"><a class="nav-link">About</a></li>
            <li class="nav-item" @click="scrollToSection('register')" style="cursor: pointer;"><a class="nav-link">Register</a></li>
        </ul>
    </div>
</div>

</nav>



<main >
    
    <!-- Alternative 1 Heading -->
<header class="header alter1-header section text-contrast" id="home">
    <div class="shapes-container">
        
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="1500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-up-right" data-aos-duration="1000" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="1000" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="1000" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-right" data-aos-duration="500" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-right" data-aos-duration="500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="zoom-out" data-aos-duration="2000" data-aos-delay="500"></div>
        
            <div class="shape shape-animated" data-aos="fade-up-right" data-aos-duration="500" data-aos-delay="200"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0"></div>
        
            <div class="shape shape-animated" data-aos="fade-down" data-aos-duration="500" data-aos-delay="0"></div>
        
            <div class="shape shape-animated" data-aos="fade-up-right" data-aos-duration="500" data-aos-delay="100"></div>
        
            <div class="shape shape-animated" data-aos="fade-down-left" data-aos-duration="500" data-aos-delay="0"></div>
        
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-6">
          
                <h1 class="text-contrast display-4 display-md-3"><span class="bold">NightWise</span></h1>
                <p class="lead bold">Elavating nightclub experience 
throgh innovative solution</p>

                <p class="lead">Elavating nightclub experience 
throgh innovative solution</p>

                <nav class="nav my-5">

                    <a href="#demos" class="btn btn-rounded btn-contrast scrollto">Explore</a>
                </nav>
            </div>

            <div class="col-md-6">
                <div class="iphone front mx-auto">
                    <div class="screen shadow-box">
                        <img src="../assets/img/screens/app/9.png" alt="...">
                    </div>
                    <div class="notch"></div>
                </div>
            </div>
        </div>
    </div>
</header>

    <!-- Features -->
<section class="section mt-6n" id="features">
    <div class="container pt-0">
        <div class="bg-contrast shadow rounded p-5">
            <div class="row gap-y">
                

                
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-table pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Table Reservations </h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">With club plan visualization.
For more engaging and user
friendly experience.
</p>
                    </div>
                
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-ticket pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Ticket Sale</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">Hassle free ticket purchase
for events.</p>
                    </div>
                
                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-gift pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Loyalty plans</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">Fosters customer retention
and strenghtens the night
club relationship with
audience</p>
                    </div>

                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-martini-glass pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Ordering to table</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">Guests no longer need to
navigate through dense crowds
and loud music to order. Ensuring
smooth and mo</p>
                    </div>

                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-music pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Music ordering</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">Enhances guest engagement and
satisfaction by putting control in their
hands for a more tailored nightlife
experience.
</p>
                    </div>

                    <div class="col-md-4">
                        <div class="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
                            <i class="icon m-0 fa fa-user pe-3x"></i>
                        </div>
                        <h4 class="semi-bold mt-4 text-capitalize">Customer engagement</h4>

                        <hr class="w-25 bw-2 border-alternate mt-3 mb-4">
                        <p class="regular text-muted">All our innovative features will drive
guests to explore the platform and
engage them with events. </p>
                    </div>
                
            </div>
        </div>
    </div>
</section>

    <!-- Section Proposal -->
<section class="section our-proposal">
    <div class="shapes-container">
        <div class="shape shape-circle pattern-dots" data-aos="fade-up" data-aos-delay="200"></div>
        <div class="shape shape-circle shape-circle-fill" data-aos="fade-up"></div>

        <div class="pattern pattern-dots"></div>
    </div>

    <div class="container bring-to-front">
        <div class="row align-items-center">
            <div class="col-md-6 order-md-last">
                <figure class="mockup" style="width: 920px; max-width: 100%;">
                    <img src="../assets/img/zena.png" class="img-responsive" alt="...">
                </figure>
            </div>

            <div class="col-md-6">
                <h2 class="heading-line">Our Mission</h2>
                <p class="lead text-muted">We decided to empower Nightclubs with
innovative solutions while providing guests
with a seamless nightlife experience.
For that reason, NightWise Guest and Staff
interface was created.</p>
            </div>
        </div>
    </div>
</section>


    <!-- Powerful Features -->
<section class="section alter3-features" id="features">
    <div class="shapes-container">
        <div class="shape shape-ring shape-ring-1">
            <div class="animation animation--rotating"></div>
        </div>
        <div class="shape shape-ring shape-ring-2">
            <div class="animation animation--rotating"></div>
        </div>
        <div class="shape shape-circle animation--clockwise">
            <div></div>
        </div>

        <div class="shape background-shape-main"></div>
    </div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 order-lg-last">
                <div class="section-heading">
                    <p class="px-2 text-alternate text-uppercase bold">Powerful features await for you</p>
                    <h2 class="heading-line">A complete feature stack ready to help you</h2>

                    <p class="lead text-muted my-4 semi-bold">Easily manage events</p>

                    <p>Upload events, sell tickets and tables reservations. Easily manage all data. Enjoy number of our automated marketing features from automatic emailing system to promotions through the NightWise guest app. NightWise is here to help you get more emails by encouraging users to share it. </p>

                </div>
            </div>

            <div class="col-lg-7 pr-lg-6">
                <div class="row">
                    <div class="col-lg-6 rotated-cards">
                        
                        
    <div data-aos="fade-down" data-aos-delay="0">
        <div class="card border-0 shadow mb-5 tilt">
            <div class="card-body py-5 px-4">
                <div class="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                    <i class="icon m-0 pe fa fa-moon pe-3x"></i>
                </div>

                <h4 class="bold mb-5">NightWise Guest</h4>

                <p class="text-muted lead">Guest interface isn’t just a set of
features. It's a heartbeat of our
marketing strategy. A magnet that
connects customers to nightclubs
far beyond individual events.</p>
            </div>
        </div>
    </div>

    <div data-aos="fade-down" data-aos-delay="500">
        <div class="card border-0 shadow mb-5 tilt">
            <div class="card-body py-5 px-4">
                <div class="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                    <i class="icon m-0 pe fa fa-envelope pe-3x"></i>
                </div>

                <h4 class="bold mb-5">Email Newsletter</h4>

                <p class="text-muted lead">Staff effortlessly monitors and expands
their customer database.
Guest sign-ups for newsletters during
logins, streamlines data collection for
targeted event campaigns.</p>
            </div>
        </div>
    </div>

                    </div>

                    <div class="col-lg-6 rotated-cards">
                        
                        
    <div data-aos="fade-down" data-aos-delay="800">
        <div class="card border-0 shadow mb-5 tilt">
            <div class="card-body py-5 px-4">
                <div class="icon-box rounded-circle gradient gradient-primary-light text-contrast shadow icon-xl mb-3">
                    <i class="icon m-0 fa fa-instagram pe-3x"></i>
                </div>

                <h4 class="bold mb-5">Instagram Marketing
</h4>

                <p class="text-muted lead">A simple button click sparks
automated instagram posts. A
powerful and hassle free tool for
instant and effective event
promotion.</p>
            </div>
        </div>
    </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<br>
    <!-- Integrations -->
<section class="section integration-bubbles">
    <div class="container overflow-hidden">
        <div class="row">
            <div class="col-md-6">
                <div class="bubbles-wrapper">
                    <div class="animations m-0">
                        
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                                <img src="../assets/img/fantasea.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Fantasea</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                                <img src="../assets/img/fantasea.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Fantasea</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                                <img src="../assets/img/fantasea.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Fantasea</span>
                            </div>
                        
                   
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-lg">
                                <img src="../assets/img/fantasea.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Fantasea</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                                <img src="../assets/img/fantasea.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Fantasea</span>
                            </div>
                        
      
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                                <img src="../assets/img/fantasea.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Fantasea</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-md">
                                <img src="../assets/img/fantasea.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Fantasea</span>
                            </div>
                        
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xxl">
                                <img src="../assets/img/fantasea.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Fantasea</span>
                            </div>
                        
             
                            <div class="bubble bg-contrast rounded-circle p-2 shadow icon icon-xl">
                                <img src="../assets/img/fantasea.png" alt="" class="img-responsive" >
                                <span class="badge badge-contrast shadow-box">Fantasea</span>
                            </div>
                        
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="section-heading">
                    <p class="text-alternate text-uppercase bold">We are trusted by many clubs and organizators</p>
                    <h2 class="heading-line">Extending our reach</h2>
                    <p class="lead text-muted">
                        NightWise prioritizes easy sign
-up and intuitive
customization.
Clubs can easily personalize their page, the result?
A uniquely tailored presence on our platform.
Guests can then simply engage with all the nightclubs
signed in our platform. Providing benefits for both sides
                    </p>
                </div>

                <a href="#!" class="more-link">Explore all integration</a>
            </div>
        </div>
    </div>
</section>
    <!-- Why Choose Us -->
<section class="section why-choose-us">
    <div class="shapes-container">
        <div class="pattern pattern-dots"></div>
    </div>

    <div class="container pb-8 bring-to-front">
        <div class="section-heading text-center">
            <h2 class="heading-line">Why you should choose NightWise <i class="fa fa-moon"></i>?</h2>
            <p class="text-muted lead mx-auto">It provides innovative solutions in the era of digitalisation. We aim to engage users with your club and drive sale and customer satisfaction up, for completely free. <b>Why not?</b></p>
        </div><br><br>

        <div class="row gap-y">
            <div class="col-md-6">
                <h4 class="bold text-alternate">Managing Events</h4>
                <p class="text-muted lead mb-5">Use our marketing features such as automatic emailing, instagram publishing and presence on our platform. </p>

                <ul class="list-unstyled why-icon-list">
                    
                    
                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-couch fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Table Reservations</h5>
                                    <p class="my-0">Depending on the club plan you upload. Guest will see an interactive plan with all the tables. They can then click on the table and reserve it. Reservations can be made for free or by paying deposit depending on your choice.</p>
                                </div>
                            </div>
                        </li>
                    
                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-arrow-trend-up fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Marketing</h5>
                                    <p class="my-0">Use our tools like automatic emailing, instagram publishing and presence on our platform to engage more customers with your club. Modify loyalty rewards to pull more users</p>
                                </div>
                            </div>
                        </li>
                    
                        <li class="list-item">
                            <div class="media align-items-center">
                                <div class="icon-shape mr-3">
                                    <div class="shape shape-pipes"></div>
                                    <i class="icon text-alternate fa fa-ticket fa-3x"></i>
                                </div>

                                <div class="media-body">
                                    <h5 class="bold">Ticket Sale</h5>
                                    <p class="my-0">Set event specific quotas and sell tickets. On the staff platform you will find qr code scanners which check the validity of ticket. Our comission for ticket sale is the lowest on the market.</p>
                                </div>
                            </div>
                        </li>
                    
                </ul>

                <hr class="my-5">
              
            </div>

            <div class="col-md-6">
                <div class="rotated-mockups device-twin">
                    <div class="browser absolute shadow-lg">
                        <img src="../assets/img/eventitablet.png" alt="...">
                    </div>

                    <div class="front iphone light">
                        <div class="screen">
                            <img src="../assets/img/mob1.png" alt="...">
                        </div>

                        <div class="notch"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    
  
    <!-- Stay Tunned -->
<section class="section stay-tunned" id="register">
    <div class="container bring-to-front">
        <div class="shadow rounded p-5 bg-contrast overflow-hidden">
            <div class="section-heading text-center">
                <h2 class="heading-line">Register on our platform</h2>
                <p class="lead text-alternate">Be a part of the NightWise family. <br>Get credentials to your admin page in seconds.</p>
            </div>

            <div class="mx-auto position-relative form-wrapper">
                <form action="srv/register.php" class="form text-center" data-response-message-animation="slide-in-left" novalidate>
                    <div class="form-group input--group">
                
                        <input id="subscribe-email" type="text" name="Subscribe[email]" class="form-control form-control-lg text-center" placeholder="Night Club name"  style="border-radius:20px;" required><br>
                        <input id="subscribe-email" type="email" name="Subscribe[email]" class="form-control form-control-lg text-center" placeholder="Your email" style="border-radius:20px;" required>
                    </div>

                    <button type="submit" class="more-link btn btn-primary text-contrast bold">Start your journey with us</button>
                </form>
            

                <div class="response-message">
                    <i class="fas fa-envelope font-lg"></i>
                    <p class="font-md m-0">Check your email</p>
                    <p class="response">We sent you an email with a link to get started. You’ll be in your account in no time.</p>
                </div>
            </div>
        </div>
    </div>
</section>


</main>


    <!-- Footer -->
<footer class="site-footer section bg-dark text-contrast edge top-left">
    <div class="container py-3">
        <div class="row gap-y text-center text-md-left">
            <div class="col-md-4 mr-auto">
                <img src="../assets/img/logonobg.png" alt="" class="logo">

                <p>We provide innovative solutions for nightclubs</p>
            </div>

            
            <div class="col-md-2">
                <h6 class="py-2 small">Follow us</h6>

                <nav class="nav justify-content-around">
                    <a href="https://facebook.com/5studios.net" target="_blank" class="btn btn-circle btn-sm brand-facebook"><i class="fab fa-facebook"></i></a>
                    <a href="#" class="btn btn-circle btn-sm brand-twitter"><i class="fab fa-twitter"></i></a>
                    <a href="#" class="btn btn-circle btn-sm brand-instagram"><i class="fab fa-instagram"></i></a>
   
                </nav>
            </div>
        </div>

        <hr class="mt-5 op-5" />
        <div class="row small">
            <div class="col-md-4">
                <p class="mt-2 mb-0 text-center text-md-left">© 2023 NightWise All Rights Reserved</p>
            </div>
        </div>
    </div>
</footer>



</template>

<script>

export default {
  name: 'HomeView',
  methods:{
    scrollToSection(sectionId) {
      // Find the element with the specified ID
      const targetElement = document.getElementById(sectionId);

      // Check if the element exists
      if (targetElement) {
        // Scroll to the element using smooth behavior
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }

}
</script>


<style>
  @import '../assets/css/all.css';
</style>
https://5studios.net/
<style>
  @import '../assets/css/aos.css';
</style>

<style>
  @import '../assets/css/laapp.css';
</style>

<style>
  @import '../assets/css/swiper.css';
</style>

<style>
  @import '../assets/css/pe-icon-7-stroke.css';
</style>

<style>
  @import '../assets/css/odometer-theme-minimal.css';
</style>
