import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PdfView from '../views/PdfView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pdf/',
    name: 'pdf',
    component: PdfView
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/presentation') {
    window.location.href = 'https://shorturl.at/blo57';
  } else {
    next();
  }
});

export default router
