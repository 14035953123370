<template>


</template>


<script>

export default {
  name: 'HomeView',
  mounted(){
    window.location.href = 'https://drive.google.com/file/d/17jIqNJPpAXsDePeQc0AIfNOVHMDsJ0-I/view?usp=sharing'
  }


}
</script>


